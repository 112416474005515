<template>
   <v-container 
      style="max-width: 2000px;padding-top:66px;background-color:white;height:100vh;width:100vw;overflow-y:auto;overflow-x:hidden;"
      class="bgImage"
      :style="{
         'background-image': backgroundImg?backgroundImg:'none',
         'background-color': backgroundColor?backgroundColor:'none'
         }"
      >
      <v-row class="pa-1 no-blur">
         <v-col align="center" cols="12">
            <v-card style="max-width:600px;">
               <v-card-title class="py-1 px-3"
                  style="color:#666666;font-weight:600;border-bottom: 1px solid #CCCCCC;">
                  <v-icon class="mr-2">mdi-cog</v-icon>
                  Đổi mật khẩu
                  <v-spacer/>
                  <v-btn dark 
                     class="px-3 text-none"
                     color="#1565C0"
                     @click="savePassword">
                     <b>Áp dụng</b>
                  </v-btn>
               </v-card-title>

               <v-card-text class="px-6 py-1"
                  style="height:calc(100vh - 160px);max-height:calc(100vh - 160px);overflow-y:auto;">
                  <v-row class="pt-4"> 
                     <v-col cols="12" class="pa-0">
                        <v-card-subtitle
                           class="pa-0 py-1 px-4"
                           v-show="showError"
                        >
                           <div :style="{'background-color': errForm.color}"
                              style="font-size:16px;font-weight: 600;border-radius:4px;"
                              class="py-1 d-block white--text text-center">
                              {{ errForm.message }}
                           </div>
                        </v-card-subtitle>
                     </v-col>
                     <v-col cols="12" class="mt-1 pa-0 px-4">
                        <v-text-field
                           ref="oldPassword"
                           style="font-size:20px;font-weight:600;"
                           v-model="oldPassword"
                           :rules="[rules.required, rules.minPassword]"
                           label="Mật khẩu hiện tại *"
                           :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                           :type="showOldPassword ? 'text' : 'password'"
                           @click:append="showOldPassword = !showOldPassword"
                           outlined dense
                           @focus="showKeyboard"
                           ></v-text-field>
                     </v-col>

                     <v-col cols="12" class="mt-1 pa-0 px-4">
                        <v-text-field
                        ref="newPassword"
                        style="font-size:20px;font-weight:600;"
                        v-model="newPassword"
                        :rules="[rules.required, rules.minPassword]"
                        label="Mật khẩu mới *"
                        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showNewPassword ? 'text' : 'password'"
                        @click:append="showNewPassword = !showNewPassword"
                        outlined dense
                        @focus="showKeyboard"
                        ></v-text-field>
                     </v-col>

                     <v-col cols="12" class="mt-1 pa-0 px-4">
                        <v-text-field
                        ref="confirmPassword"
                        style="font-size:20px;font-weight:600;"
                        v-model="confirmPassword"
                        :rules="[rules.required, rules.minPassword, rules.confirmPass]"
                        label="Nhập lại mật khẩu mới *"
                        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showNewPassword ? 'text' : 'password'"
                        @click:append="showNewPassword = !showNewPassword"
                        outlined dense
                        @focus="showKeyboard"
                        ></v-text-field>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
   props: ['modal', 'customStyle'],
   data () {
      return {
         backgroundImg: null,
         backgroundColor: null,
         blurBackground: true,
         socketAskKey: new Date().getTime(),
         oldPassword: "",
         showOldPassword: false,
         newPassword: "",
         showNewPassword: false,
         confirmPassword: "",
         errForm: {
            status: false,
            message: "",
            color: 'red'
         },
         rules: {
            required: (value) => !!value || "*Bắt buộc",
            minPassword: (v) => v.length >= 6 || "Ít nhất 6 ký tự",
            confirmPass: (value) =>
               (value && value == this.newPassword) ||
               "Mật khẩu không khớp!",
         },
      }
   },
   computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
      }),
      showError() {
        return this.errForm.status;
      },
   },
   watch: {
      show(val) {
        if (val) {
          setTimeout(() => {
            this.resetValidate();
          }, 50);
        } else {
          this.newPassword = "";
          this.oldPassword = "";
        }
      },
   },
   methods: {
      resetValidate() {
        if (
          this.$refs.oldPassword != undefined &&
          !this.$refs.oldPassword.validate()
        ) {
          this.$refs.oldPassword.reset();
        }
        if (
          this.$refs.newPassword != undefined &&
          !this.$refs.newPassword.validate()
        ) {
          this.$refs.newPassword.reset();
        }
        if (
          this.$refs.confirmPassword != undefined &&
          !this.$refs.confirmPassword.validate()
        ) {
          this.$refs.confirmPassword.reset();
        }
      },
      isValidate() {
        let result = true;
        this.$refs.oldPassword.validate(true);
        this.$refs.newPassword.validate(true);
        this.$refs.confirmPassword.validate(true);

        if (
          !this.$refs.oldPassword.validate() ||
          !this.$refs.newPassword.validate() ||
          !this.$refs.confirmPassword.validate()
        ) {
           
          this.errForm.status = true
          setTimeout(() => {
            this.errForm.status = false
          }, 3000);
          this.errForm.color = 'red'
          this.errForm.message = "Lỗi! Vui lòng nhập đầy đủ thông tin!"
          result = false;
        }
        return result;
      },
      savePassword() {
        if (!this.isValidate()) return;
        var self = this;
        var path = this.$root.apiUser + "/user/changePwd/" + self.userId ;
        let payload = {
         // customer_id: this.$root.apiUserCustomerId,
         // _id: self.userId,
         // old_password: this.oldPassword,
         // new_password: this.newPassword,
         userID: this.userId,
         pwd: this.oldPassword,
         new_pwd: this.newPassword,
        };
        self.axios
            .put(path, payload, { headers: { "x-token": self.token } })
            .then((res) => {
               if(res.data.status=='OK') {
                  self.errForm.message = "Đổi mật khẩu thành công!"
                  self.errForm.color = '#689F38'
                  self.errForm.status = true
                  setTimeout(function(){
                     self.$store.dispatch("logout").then(() => {
                        // self.$router.push({path: '/login'})
                        window.location = 'login'
                     })
                  }, 2000)
               } else if (res.data.status=='ERROR_VERIFY_TOKEN') {
                  self.errForm.message = "Phiên đăng nhập hết hạn, vui lòng đăng xuất, đăng nhập lại và đổi mật khẩu."
                  self.errForm.color = 'red'
                  self.errForm.status = true
               } else {
                  console.log(res.data)
                  self.errForm.message = res.data.message
                  self.errForm.color = 'red'
                  self.errForm.status = true
               }
            }).catch(err => {
               self.errForm.color = 'red'
               self.errForm.status = true
               if( err.response ){
                  console.log(err.response.data)
                  self.errForm.message = err.response.data.message
                  return
               }
               self.errForm.message = err.message
            })
      },
      showKeyboard (e) {
         this.$eventBus.$emit("requestShowKeyboard", e.target, 'normal')
      }
   }
}
</script>

<style>
   
</style>